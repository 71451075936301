export const createAdvertisement = /* GraphQL */ `
  mutation CreateAdvertisement(
    $input: CreateAdvertisementInput!
    $condition: ModelAdvertisementConditionInput
  ) {
    createAdvertisement(input: $input, condition: $condition) {
      id
      link
      imageLocation
      order
      lang
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAdvertisement = /* GraphQL */ `
  mutation UpdateAdvertisement(
    $input: UpdateAdvertisementInput!
    $condition: ModelAdvertisementConditionInput
  ) {
    updateAdvertisement(input: $input, condition: $condition) {
      id
      link
      imageLocation
      order
      createdAt
      updatedAt
    }
  }
`;
export const deleteAdvertisement = /* GraphQL */ `
  mutation DeleteAdvertisement(
    $input: DeleteAdvertisementInput!
    $condition: ModelAdvertisementConditionInput
  ) {
    deleteAdvertisement(input: $input, condition: $condition) {
      id
      link
      imageLocation
      order
      createdAt
      updatedAt
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      name
      brandName
      fullText
      categoryId
      subcategoryID
      subsubcategoryID
      imageFileName
      status
      interactionsScore
      createdAt
      updatedAt
    }
  }
`;
export const updateBrand = /* GraphQL */ `
  mutation UpdateBrand(
    $input: UpdateBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    updateBrand(input: $input, condition: $condition) {
      id
      name
      status
      createdAt
      updatedAt
    }
  }
`;
export const createBrand = /* GraphQL */ `
  mutation CreateBrand(
    $input: CreateBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    createBrand(input: $input, condition: $condition) {
      id
      name
      status
      createdAt
      updatedAt
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      name
      brand {
        id
        name
        status
        createdAt
        updatedAt
      }
      brandId
      brandName
      fullText
      categoryId
      subcategoryID
      subsubcategoryID
      imageFileName
      status
      interactionsScore
      userId
      createdAt
      updatedAt
    }
  }
`;
export const createCollection = /* GraphQL */ `
  mutation CreateCollection(
    $input: CreateCollectionInput!
    $condition: ModelCollectionConditionInput
  ) {
    createCollection(input: $input, condition: $condition) {
      id
      title
      order
      translations {
        language
        name
        __typename
      }
      products {
        items {
          id
          productId
          collectionId
          product {
            id
            name
            brand {
              id
              name
              status
              createdAt
              updatedAt
              __typename
            }
            brandId
            brandName
            fullText
            categoryId
            subcategoryID
            subsubcategoryID
            imageFileName
            status
            interactionsScore
            userId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      subsubcategoryID
      hidden
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCollection = /* GraphQL */ `
  mutation UpdateCollection(
    $input: UpdateCollectionInput!
    $condition: ModelCollectionConditionInput
  ) {
    updateCollection(input: $input, condition: $condition) {
      id
      title
      order
      translations {
        language
        name
        __typename
      }
      products {
        items {
          id
          productId
          collectionId
          product {
            id
            name
            brand {
              id
              name
              status
              createdAt
              updatedAt
              __typename
            }
            brandId
            brandName
            fullText
            categoryId
            subcategoryID
            subsubcategoryID
            imageFileName
            status
            interactionsScore
            userId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      subsubcategoryID
      hidden
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCollection = /* GraphQL */ `
  mutation DeleteCollection(
    $input: DeleteCollectionInput!
    $condition: ModelCollectionConditionInput
  ) {
    deleteCollection(input: $input, condition: $condition) {
      id
      title
      order
      translations {
        language
        name
        __typename
      }
      products {
        items {
          id
          productId
          collectionId
          product {
            id
            name
            brand {
              id
              name
              status
              createdAt
              updatedAt
              __typename
            }
            brandId
            brandName
            fullText
            categoryId
            subcategoryID
            subsubcategoryID
            imageFileName
            status
            interactionsScore
            userId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      subsubcategoryID
      hidden
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCollectionProducts = /* GraphQL */ `
  mutation CreateCollectionProducts(
    $input: CreateCollectionProductsInput!
    $condition: ModelCollectionProductsConditionInput
  ) {
    createCollectionProducts(input: $input, condition: $condition) {
      id
      productId
      collectionId
      product {
        id
        name
        brandId
        brandName
        fullText
        categoryId
        subcategoryID
        subsubcategoryID
        imageFileName
        status
        interactionsScore
        userId
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCollectionProducts = /* GraphQL */ `
  mutation DeleteCollectionProducts(
    $input: DeleteCollectionProductsInput!
    $condition: ModelCollectionProductsConditionInput
  ) {
    deleteCollectionProducts(input: $input, condition: $condition) {
      id
      productId
      collectionId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createImage = /* GraphQL */ `
  mutation CreateImage(
    $input: CreateImageInput!
    $condition: ModelImageConditionInput
  ) {
    createImage(input: $input, condition: $condition) {
      url
      productId
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteImage = /* GraphQL */ `
  mutation DeleteImage(
    $input: DeleteImageInput!
    $condition: ModelImageConditionInput
  ) {
    deleteImage(input: $input, condition: $condition) {
      url
      productId
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateReview = /* GraphQL */ `
  mutation UpdateReview(
    $input: UpdateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    updateReview(input: $input, condition: $condition) {
      id
      text
      rating
      productId
      userId
      Likes {
        items {
          id
          reviewId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
