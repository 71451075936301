import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getCollection } from "../graphql-use/queries";
import { generateClient } from "aws-amplify/api";
import {
  updateCollection,
  createCollectionProducts,
  deleteCollectionProducts
} from "../graphql-use/mutations";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";

const CollectionPage = () => {
  const { collectionId } = useParams();
  const [collection, setCollection] = useState(null);

  const [title, setTitle] = useState("");
  const [order, setOrder] = useState(1);
  const [translations, setTranslations] = useState([]);
  const [hidden, setHidden] = useState(false);
  const [products, setProducts] = useState([]);
  const [newProductId, setNewProductId] = useState("");
  const [subsubcategoryId, setSubsubcategoryId] = useState("");

  const client = generateClient();

  useEffect(() => {
    const fetchCollection = async () => {
      try {
        const result = await client.graphql({
          query: getCollection,
          variables: { id: collectionId }
        });
        const fetchedCollection = result.data.getCollection;

        // Set initial values for editable fields
        setTitle(fetchedCollection.title);
        setOrder(fetchedCollection.order);
        setTranslations(fetchedCollection.translations);
        setHidden(fetchedCollection.hidden);
        setProducts(fetchedCollection.products.items);
        setSubsubcategoryId(fetchedCollection.subsubcategoryID ?? "");

        // Set the entire collection for display purposes
        setCollection(fetchedCollection);
      } catch (error) {
        console.error("Error fetching collection", error);
      }
    };

    fetchCollection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionId]);

  if (!collection) {
    return <div>Loading...</div>;
  }

  // Handler for saving changes
  const handleSave = async () => {
    try {
      // Prepare translations for update
      const updatedTranslations = translations.map((translation) => ({
        language: translation.language,
        name: translation.name
      }));

      // Build the input object
      const input = {
        id: collectionId,
        title: title,
        order: order,
        translations: updatedTranslations,
        hidden: hidden
      };

      // Conditionally add subsubcategoryID if it is not null or undefined
      if (
        subsubcategoryId !== null &&
        subsubcategoryId !== undefined &&
        subsubcategoryId !== ""
      ) {
        input.subsubcategoryID = subsubcategoryId;
      }

      // Send API call to update collection
      const result = await client.graphql({
        query: updateCollection,
        variables: {
          input
        }
      });

      // Optionally, update the collection state with the saved values
      setCollection(result.data.updateCollection);
      NotificationManager.success(
        "Collection information saved successfully",
        "Success"
      );
    } catch (error) {
      console.error("Error updating collection", error);
      NotificationManager.error("Error updating collection", "Error");
    }
  };

  // Handler for updating translations
  const handleTranslationChange = (language, name) => {
    const updatedTranslations = translations.map((translation) => {
      if (translation.language === language) {
        return { ...translation, name };
      }
      return translation;
    });
    setTranslations(updatedTranslations);
  };

  // Handler for adding a new product to the collection
  const handleAddProduct = async () => {
    try {
      // Trim whitespace from newProductId
      const trimmedProductId = newProductId.trim();

      // Validate the newProductId
      if (!trimmedProductId) {
        NotificationManager.error(
          "Product ID cannot be empty or contain only spaces",
          "Error"
        );
        return;
      }

      // Send API call to add product to collection
      const result = await client.graphql({
        query: createCollectionProducts,
        variables: {
          input: {
            collectionId: collectionId,
            productId: trimmedProductId
          }
        }
      });

      // Update the products state with the newly added product
      setProducts([...products, result.data.createCollectionProducts]);

      // Clear the input field after adding the product
      setNewProductId("");

      NotificationManager.success(
        "Product added to collection successfully",
        "Success"
      );
    } catch (error) {
      console.error("Error adding product to collection", error);
      NotificationManager.error("Error adding product to collection", "Error");
    }
  };

  // Handler for deleting a product from the collection
  const handleDeleteProduct = async (id, productId) => {
    try {
      // Send API call to delete product from collection
      await client.graphql({
        query: deleteCollectionProducts,
        variables: {
          input: {
            id: id
          }
        }
      });

      // Update the products state by filtering out the deleted product
      setProducts(products.filter((item) => item.product.id !== productId));

      NotificationManager.success(
        "Product deleted from collection successfully",
        "Success"
      );
    } catch (error) {
      console.error("Error deleting product from collection", error);
      NotificationManager.error(
        "Error deleting product from collection",
        "Error"
      );
    }
  };

  return (
    <div style={{ padding: "20px", maxWidth: "800px", margin: "auto" }}>
      <h2 style={{ marginBottom: "20px", borderBottom: "1px solid #ddd" }}>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          style={{
            width: "100%",
            padding: "8px",
            fontSize: "1.5em",
            fontWeight: "bold",
            border: "none",
            outline: "none"
          }}
        />
      </h2>
      <p>
        <strong>ID:</strong> {collection.id}
      </p>
      <p>
        <strong>Order:</strong>{" "}
        <input
          type="number"
          value={order}
          onChange={(e) => setOrder(parseInt(e.target.value))}
        />
      </p>
      {/* Inputs for translations */}
      {translations.map((translation, index) => (
        <div key={index}>
          <p>
            <strong>{translation.language.toUpperCase()}:</strong>{" "}
            <input
              type="text"
              value={translation.name}
              onChange={(e) =>
                handleTranslationChange(translation.language, e.target.value)
              }
            />
          </p>
        </div>
      ))}
      <p>
        <strong>Hidden:</strong>{" "}
        <input
          type="checkbox"
          checked={hidden}
          onChange={(e) => setHidden(e.target.checked)}
        />
      </p>
      {products.length === 0 && (
        <div>
          <p>
            <strong>Subsubcategory ID:</strong>{" "}
            <input
              type="text"
              value={subsubcategoryId}
              onChange={(e) => setSubsubcategoryId(e.target.value)}
            />
          </p>
          <p>Either add a Subsubcategory ID or add products one by one.</p>
        </div>
      )}
      <button
        onClick={handleSave}
        style={{
          padding: "10px",
          fontSize: "1em",
          backgroundColor: "#ff7d69",
          color: "white",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          marginTop: "12px"
        }}
      >
        Save
      </button>
      {subsubcategoryId.trim().length === 0 && (
        <div>
          <h3
            style={{
              marginTop: "42px"
            }}
          >
            Products in Collection:
          </h3>
          <div
            style={{
              display: "grid",
              gridGap: "20px",
              gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))"
            }}
          >
            {products.map((item) => (
              <div
                key={item.product.id}
                style={{
                  border: "1px solid #ddd",
                  padding: "10px",
                  borderRadius: "5px"
                }}
              >
                <img
                  src={item.product.imageFileName}
                  alt={item.product.name}
                  style={{ maxWidth: "100%", marginBottom: "10px" }}
                />
                <p>
                  <strong>Name:</strong> {item.product.name}
                </p>
                <p>
                  <strong>Brand:</strong> {item.product.brandName}
                </p>
                <p>
                  <strong>ID:</strong> {item.product.id}
                </p>
                <button
                  onClick={() => handleDeleteProduct(item.id, item.productId)}
                  style={{
                    padding: "6px",
                    fontSize: "0.8em",
                    backgroundColor: "#ff7d69",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer"
                  }}
                >
                  Delete
                </button>
              </div>
            ))}
          </div>
          <div style={{ marginTop: "20px" }}>
            <input
              type="text"
              placeholder="Enter Product ID"
              value={newProductId}
              onChange={(e) => setNewProductId(e.target.value)}
              style={{ padding: "8px", marginRight: "10px", fontSize: "1em" }}
            />
            <button
              onClick={handleAddProduct}
              style={{
                padding: "10px",
                fontSize: "1em",
                backgroundColor: "#4CAF50",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer"
              }}
            >
              Add Product
            </button>
          </div>
        </div>
      )}

      <NotificationContainer />
    </div>
  );
};

export default CollectionPage;
