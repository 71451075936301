import React, { useState, useEffect } from "react";
import { useDrag, useDrop } from "react-dnd";
import { generateClient } from "aws-amplify/api";
import { listAdvertisements } from "../graphql-use/queries";
import {
  updateAdvertisement,
  createAdvertisement,
  deleteAdvertisement
} from "../graphql-use/mutations";
import { remove, uploadData, getUrl } from "aws-amplify/storage";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import { v4 as uuidv4 } from "uuid";
import { supportedLanguages } from "../utils/utils";

const Ad = ({ ad, moveAd, findAd, updateAdLink, onDeleteAd }) => {
  const originalIndex = findAd(ad.id).index;
  const [editableLink, setEditableLink] = useState(ad.link);
  const client = generateClient();

  const [, drag] = useDrag({
    type: "AD",
    item: { id: ad.id, originalIndex }
  });

  const [, drop] = useDrop({
    accept: "AD",
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.originalIndex;
      const hoverIndex = findAd(ad.id).index;
      if (dragIndex === hoverIndex) {
        return;
      }
      moveAd(dragIndex, hoverIndex);
      item.originalIndex = hoverIndex;
    }
  });

  const handleDeleteAd = async (ad) => {
    try {
      await client.graphql({
        query: deleteAdvertisement,
        variables: { input: { id: ad.id } }
      });

      // TODO: Remove from s3 doesnt work
      const key = ad.imageLocation.split(".com/")[1];
      await remove(key).catch();

      onDeleteAd(ad.id);
      NotificationManager.success(
        "Advertisement deleted successfully",
        "Success"
      );
    } catch (error) {
      console.error("Error deleting advertisement", error);
      NotificationManager.error("Error deleting advertisement", "Error");
    }
  };

  const ref = React.useRef();
  drag(drop(ref));

  useEffect(() => {
    if (ad.link !== editableLink) {
      updateAdLink(ad.id, editableLink);
    }
  }, [editableLink, ad.link, ad.id, updateAdLink]);

  return (
    <tr
      ref={ref}
      key={ad.id}
      style={{ ...styles.tableRow, backgroundColor: "#f9f9f9" }}
    >
      <td style={styles.tableCell}>{ad.id}</td>
      <td style={styles.tableCell}>
        <input
          type="text"
          value={editableLink}
          onChange={(e) => setEditableLink(e.target.value)}
          style={{ width: "100%" }}
        />
      </td>
      <td style={styles.tableCell}>
        <img
          src={ad.imageLocation}
          alt={`Ad ${ad.id}`}
          style={styles.adImage}
        />
      </td>
      <td style={styles.tableCell}>{ad.lang}</td>
      <td style={styles.tableCell}>
        <button onClick={() => handleDeleteAd(ad)} style={styles.deleteButton}>
          Delete
        </button>
      </td>
    </tr>
  );
};

const AdsManager = () => {
  const [ads, setAds] = useState([]);
  const [filteredAds, setFilteredAds] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("all"); // Default to showing all ads
  const [newAdLink, setNewAdLink] = useState("");
  const [newAdImage, setNewAdImage] = useState(null);
  const [newAdLang, setNewAdLang] = useState("");

  const client = generateClient();

  useEffect(() => {
    fetchAds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    filterAdsByLanguage(selectedLanguage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ads, selectedLanguage]);

  const fetchAds = async () => {
    try {
      const result = await client.graphql({
        query: listAdvertisements
      });
      const sortedAds = result.data.listAdvertisements.items.sort(
        (a, b) => a.order - b.order
      );
      setAds(sortedAds.map((ad, index) => ({ ...ad, index })));
    } catch (error) {
      console.error("Error fetching ads", error);
    }
  };

  const filterAdsByLanguage = (language) => {
    if (language === "all") {
      setFilteredAds(ads);
    } else if (language === "none") {
      setFilteredAds(
        ads.filter((ad) => ad.lang === undefined || ad.lang === null)
      );
    } else {
      setFilteredAds(ads.filter((ad) => ad.lang === language));
    }
  };

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const handleDeleteAd = (deletedAdId) => {
    setAds((prevAds) => prevAds.filter((prevAd) => prevAd.id !== deletedAdId));
  };

  const handleAddAd = async () => {
    if (!newAdImage) {
      NotificationManager.error(
        "Please provide an image for the new ad",
        "Error"
      );
      return;
    }

    try {
      const imageLocation = await uploadImageToS3(newAdImage);

      // Build the input object conditionally
      const adInput = {
        link: newAdLink,
        imageLocation: imageLocation,
        order: ads.length + 1
      };

      // Only add `lang` if `newAdLang` is not "none"
      if (newAdLang && newAdLang !== "none") {
        adInput.lang = newAdLang;
      }

      const result = await client.graphql({
        query: createAdvertisement,
        variables: { input: adInput }
      });

      const newAd = result.data.createAdvertisement;
      setAds([...ads, { ...newAd, index: ads.length }]);
      NotificationManager.success("New ad added successfully", "Success");

      // Reset form fields
      setNewAdLink("");
      setNewAdImage(null);
      setNewAdLang("");
    } catch (error) {
      console.error("Error adding new ad", error);
      NotificationManager.error("Error adding new ad", "Error");
    }
  };

  const uploadImageToS3 = async (imageFile) => {
    const result = await uploadData({
      key: `advertisements/ad-${uuidv4()}.jpg`,
      data: imageFile,
      options: {
        contentType: "image/jpeg",
        accessLevel: "guest"
      }
    }).result;

    const getUrlResult = await getUrl({
      key: result.key
    });
    return getUrlResult.url.toString().split("?")[0];
  };

  const moveAd = (fromIndex, toIndex) => {
    const updatedAds = [...ads];
    const [movedAd] = updatedAds.splice(fromIndex, 1);
    updatedAds.splice(toIndex, 0, movedAd);
    const updatedAdsWithOrder = updatedAds.map((ad, index) => ({
      ...ad,
      order: index + 1
    }));
    setAds(updatedAdsWithOrder);
  };

  const findAd = (id) => {
    const ad = ads.find((ad) => ad.id === id);
    return { ad, index: ad ? ad.index : null };
  };

  const updateAdLink = (adId, newLink) => {
    setAds((prevAds) =>
      prevAds.map((ad) => (ad.id === adId ? { ...ad, link: newLink } : ad))
    );
  };

  const handleSaveOrder = async () => {
    try {
      await Promise.all(
        ads.map(async (ad, index) => {
          await client.graphql({
            query: updateAdvertisement,
            variables: {
              input: { id: ad.id, order: index + 1, link: ad.link }
            }
          });
        })
      );
      NotificationManager.success(
        "Order and links saved successfully",
        "Success"
      );
    } catch (error) {
      console.error("Error saving order and links", error);
      NotificationManager.error("Error saving order and links", "Error");
    }
  };

  return (
    <div style={styles.container}>
      <h2>Advertisements</h2>
      <div>
        <label>Select Language: </label>
        <select
          onChange={handleLanguageChange}
          value={selectedLanguage}
          style={styles.input}
        >
          <option value="all">All Languages</option>
          {supportedLanguages.map((lang) => (
            <option key={lang} value={lang}>
              {lang.toUpperCase()}
            </option>
          ))}
          <option value="none">No Language</option>
        </select>
      </div>
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.tableHeader}>ID</th>
            <th style={styles.tableHeader}>Link</th>
            <th style={styles.tableHeader}>Image</th>
            <th style={styles.tableHeader}>Language</th>
            <th style={styles.tableHeader}>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredAds.map((ad) => (
            <Ad
              key={ad.id}
              id={ad.id}
              ad={ad}
              moveAd={moveAd}
              findAd={findAd}
              updateAdLink={updateAdLink}
              onDeleteAd={handleDeleteAd}
            />
          ))}
        </tbody>
      </table>
      <button onClick={handleSaveOrder} style={styles.saveButton}>
        Save Order and Links
      </button>
      <div style={styles.addAdContainer}>
        <h3>Add New Ad</h3>
        <input
          type="text"
          value={newAdLink}
          onChange={(e) => setNewAdLink(e.target.value)}
          style={styles.input}
          placeholder="Enter link..."
        />
        <select
          value={newAdLang}
          onChange={(e) => setNewAdLang(e.target.value)}
          style={styles.input}
        >
          <option value="">Select Language</option>
          {supportedLanguages.map((lang) => (
            <option key={lang} value={lang}>
              {lang.toUpperCase()}
            </option>
          ))}
          <option value="none">No Language</option>
        </select>
        <input
          type="file"
          onChange={(e) => setNewAdImage(e.target.files[0])}
          style={styles.input}
          accept="image/*"
        />
        <button onClick={handleAddAd} style={styles.addButton}>
          Add Ad
        </button>
      </div>
      <NotificationContainer />
    </div>
  );
};

const styles = {
  container: {
    margin: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start"
  },
  table: { width: "100%", borderCollapse: "collapse", marginTop: "10px" },
  tableHeader: {
    border: "1px solid #ddd",
    padding: "8px",
    backgroundColor: "#f2f2f2"
  },
  tableRow: { cursor: "pointer" },
  tableCell: { border: "1px solid #ddd", padding: "8px", textAlign: "center" },
  adImage: { maxHeight: "120px" },
  deleteButton: {
    padding: "5px 10px",
    cursor: "pointer",
    backgroundColor: "#f44336",
    color: "white",
    border: "none",
    borderRadius: "4px"
  },
  addAdContainer: { marginTop: 12, marginBottom: 48 },
  input: { marginRight: "10px", padding: "5px" },
  addButton: { padding: "5px 10px", cursor: "pointer" },
  saveButton: {
    marginTop: 12,
    marginLeft: "auto",
    padding: "10px",
    fontSize: "1em",
    backgroundColor: "#ff7d69",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer"
  }
};

export default AdsManager;
