import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { generateClient } from "aws-amplify/api";
import { listProducts } from "../graphql-use/queries";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [brandSearchTerm, setBrandSearchTerm] = useState("");
  const [filter, setFilter] = useState("all");
  const [hideNoReviews, setHideNoReviews] = useState(false); // New state variable to hide products without reviews
  const [categoryFilter, setCategoryFilter] = useState("all"); // New state for category filter
  const [subcategoryFilter, setSubcategoryFilter] = useState("all"); // New state for subcategory filter
  const [subsubcategoryFilter, setSubsubcategoryFilter] = useState("all"); // New state for subsubcategory filter
  const [categories, setCategories] = useState([]); // New state for categories
  const [subcategories, setSubcategories] = useState([]); // New state for subcategories
  const [subsubcategories, setSubsubcategories] = useState([]); // New state for subsubcategories

  const navigate = useNavigate();
  const client = generateClient();

  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchProducts = async () => {
    try {
      const result = await client.graphql({
        query: listProducts,
        variables: {
          limit: 2000
        }
      });
      const productsList = result.data.listProducts.items;
      setProducts(productsList);

      // Extract unique categories, subcategories, and subsubcategories
      const uniqueCategories = [
        ...new Set(productsList.map((product) => product.categoryId))
      ];
      const uniqueSubcategories = [
        ...new Set(productsList.map((product) => product.subcategoryID))
      ];
      const uniqueSubsubcategories = [
        ...new Set(productsList.map((product) => product.subsubcategoryID))
      ];

      setCategories(uniqueCategories);
      setSubcategories(uniqueSubcategories);
      setSubsubcategories(uniqueSubsubcategories);
    } catch (error) {
      console.error("Error fetching products", error);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleBrandSearch = (event) => {
    setBrandSearchTerm(event.target.value);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleHideNoReviewsChange = (event) => {
    setHideNoReviews(event.target.checked); // Update state when checkbox is toggled
  };

  const handleCategoryFilterChange = (event) => {
    setCategoryFilter(event.target.value);
  };

  const handleSubcategoryFilterChange = (event) => {
    setSubcategoryFilter(event.target.value);
  };

  const handleSubsubcategoryFilterChange = (event) => {
    setSubsubcategoryFilter(event.target.value);
  };

  const filteredProducts = products.filter((product) => {
    // Apply general filtering based on status
    if (filter !== "all" && product.status !== filter) {
      return false;
    }
    // Check if hiding products without reviews is enabled
    if (hideNoReviews && product.Reviews.items.length === 0) {
      return false;
    }
    // Check category filter
    if (categoryFilter !== "all" && product.categoryId !== categoryFilter) {
      return false;
    }
    // Check subcategory filter
    if (
      subcategoryFilter !== "all" &&
      product.subcategoryID !== subcategoryFilter
    ) {
      return false;
    }
    // Check subsubcategory filter
    if (
      subsubcategoryFilter !== "all" &&
      product.subsubcategoryID !== subsubcategoryFilter
    ) {
      return false;
    }
    return true;
  });

  const searchedProducts = filteredProducts
    .filter(
      (product) =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
        product.brandName.toLowerCase().includes(brandSearchTerm.toLowerCase())
    )
    .sort((a, b) => a.name.localeCompare(b.name));

  const handleAddProduct = () => {
    navigate("/add-product");
  };

  return (
    <div style={{ margin: "20px" }}>
      <div>
        <div
          style={{
            marginBottom: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <div>
            <label style={{ marginRight: "16px" }}>
              Search name:
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearch}
                style={{ padding: "8px", marginRight: "8px" }}
              />
            </label>
            <label style={{ marginRight: "16px" }}>
              Search brand:
              <input
                type="text"
                value={brandSearchTerm}
                onChange={handleBrandSearch}
                style={{ padding: "8px", marginRight: "8px" }}
              />
            </label>
            <label style={{ marginRight: "16px" }}>
              Filter:
              <select
                value={filter}
                onChange={handleFilterChange}
                style={{ padding: "8px", marginRight: "8px" }}
              >
                <option value="all">All</option>
                <option value="APPROVED">Approved</option>
                <option value="PENDING">Pending</option>
                <option value="REJECTED">Rejected</option>
                <option value="DISABLED">Disabled</option>
              </select>
            </label>
            <label style={{ marginRight: "16px" }}>
              Category:
              <select
                value={categoryFilter}
                onChange={handleCategoryFilterChange}
                style={{ padding: "8px", marginRight: "8px" }}
              >
                <option value="all">All</option>
                {categories.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </label>
            <label style={{ marginRight: "16px" }}>
              Subcategory:
              <select
                value={subcategoryFilter}
                onChange={handleSubcategoryFilterChange}
                style={{ padding: "8px", marginRight: "8px" }}
              >
                <option value="all">All</option>
                {subcategories.map((subcategory) => (
                  <option key={subcategory} value={subcategory}>
                    {subcategory}
                  </option>
                ))}
              </select>
            </label>
            <label style={{ marginRight: "16px" }}>
              Subsubcategory:
              <select
                value={subsubcategoryFilter}
                onChange={handleSubsubcategoryFilterChange}
                style={{ padding: "8px", marginRight: "8px" }}
              >
                <option value="all">All</option>
                {subsubcategories.map((subsubcategory) => (
                  <option key={subsubcategory} value={subsubcategory}>
                    {subsubcategory}
                  </option>
                ))}
              </select>
            </label>
            <label style={{ marginRight: "16px" }}>
              <input
                type="checkbox"
                checked={hideNoReviews}
                onChange={handleHideNoReviewsChange}
                style={{ marginRight: "8px" }}
              />
              Hide products without reviews
            </label>
            <button onClick={fetchProducts} style={{ padding: "8px" }}>
              Refresh
            </button>
          </div>
          <button onClick={handleAddProduct} style={{ padding: "8px" }}>
            Add product
          </button>
        </div>

        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  backgroundColor: "#f2f2f2"
                }}
              >
                Name
              </th>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  backgroundColor: "#f2f2f2"
                }}
              >
                Brand
              </th>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  backgroundColor: "#f2f2f2"
                }}
              >
                ID
              </th>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  backgroundColor: "#f2f2f2"
                }}
              >
                Status
              </th>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  backgroundColor: "#f2f2f2"
                }}
              >
                Reviews
              </th>
            </tr>
          </thead>
          <tbody>
            {searchedProducts.map((product) => (
              <tr key={product.id} style={{ cursor: "pointer" }}>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <Link
                    to={`/products/${product.id}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {product.name}
                  </Link>
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <Link
                    to={`/products/${product.id}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {product.brandName}
                  </Link>
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <Link
                    to={`/products/${product.id}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {product.id}
                  </Link>
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <Link
                    to={`/products/${product.id}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {product.status}
                  </Link>
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <Link
                    to={`/products/${product.id}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {product.Reviews.items.length} (Text:{" "}
                    {
                      product.Reviews.items.filter(
                        (review) => review.text && review.text.trim() !== ""
                      ).length
                    }
                    )
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Products;
