/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_mobile_analytics_app_id": "a0af8c3bf2c14521ab8075319e93556b",
    "aws_mobile_analytics_app_region": "eu-central-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "a0af8c3bf2c14521ab8075319e93556b",
            "region": "eu-central-1"
        }
    },
    "aws_appsync_graphqlEndpoint": "https://okwj2v4keffqzha43bzz3xk72u.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_appsync_apiKey": "da2-half77dqtzhnlnifmwji5ubvam",
    "aws_cognito_identity_pool_id": "eu-central-1:4bbcf47d-7b4c-45dc-bf91-d80abd5eeb60",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_k69QWU2XJ",
    "aws_user_pools_web_client_id": "6r0i737afl68g11ikthj43a5ht",
    "oauth": {
        "domain": "cosmate-prod.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "exp://192.168.100.12:8081/,cosmate://",
        "redirectSignOut": "exp://192.168.100.12:8081/,cosmate://",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE",
        "APPLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "cosmate-app-bucket06514-prod",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
